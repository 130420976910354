import { template as template_404f7b31c8294fca9bcdb240e0c98f03 } from "@ember/template-compiler";
const FKText = template_404f7b31c8294fca9bcdb240e0c98f03(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
