import { template as template_78ec1db45b3c4b3a96fa50ae9be8b995 } from "@ember/template-compiler";
const FKLabel = template_78ec1db45b3c4b3a96fa50ae9be8b995(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
