import { template as template_b0e5db6bae224b288d660204d396c5f8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b0e5db6bae224b288d660204d396c5f8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
